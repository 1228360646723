<template>
  <v-container fluid class="text-center">
    <Loader :loading="loading" />
    <Table-Container title="Usuarios">
      <template v-slot:header-buttons>
        <Basic-Btn text="Exportar" color="white" icon="mdi-file-excel-outline" @click="downloadReport()" :loading="loading" typeButton="text"/>
      </template>
      <template v-slot:buttons>
        <Basic-Btn text="Agregar" color="primary" icon="mdi-plus" @click="dialogs.new = true" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-refresh" @click="refresh()" :loading="loading" />
      </template>
      <template v-slot:search>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </template>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search" :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template
            ><span>Editar</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'roles')">mdi-shield-account</v-icon></template
            ><span>Actualizar roles</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="viewAddress(item)">mdi-map-marker-outline</v-icon></template
            ><span>Direccion</span></v-tooltip
          >
          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="secondary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="activeUser(item.idusuario)">mdi-account-convert</v-icon></template
            ><span>Activar / Desactivar</span></v-tooltip
          >

          <v-tooltip top
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="warning" dark v-bind="attrs" v-on="on" :disabled="loading" @click="viewReset(item)">mdi-key</v-icon></template
            ><span>Actualizar contraseña</span></v-tooltip
          >
          <v-tooltip right
            ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idusuario)">mdi-delete</v-icon></template
            ><span>Eliminar</span></v-tooltip
          >
        </template>
        <template v-slot:[`item.usuarios_roles[0].rol.rol`]="{ item }">
          <v-chip-group column multiple v-if="item.usuarios_roles.length > 0">
            <v-chip v-text="item.usuarios_roles[0].rol.rol" :color="validColor(item.usuarios_roles[0].rol.rol)" :class="item.usuarios_roles[0].rol.rol === 'publico' ? 'white--text' :''" small />
            <v-edit-dialog v-if="item.usuarios_roles.length > 1">
              <v-chip v-text="item.usuarios_roles.length - 1 + '+'" small />
              <template v-slot:input>
                <v-chip-group column multiple>
                  <v-chip v-text="roles.rol.rol" v-for="(roles, n) in item.usuarios_roles" :key="n" :color="validColor(roles.rol.rol)" :class="roles.rol.rol === 'publico' ? 'white--text' :''" small />
                </v-chip-group>
              </template>
            </v-edit-dialog>
          </v-chip-group>
          <v-chip v-text="'Sin rol'" v-else small />
        </template>
        <template v-slot:[`item.usuarios_direcciones`]="{ item }">
          {{ item.usuarios_direcciones.length > 0 ? "Disponible" : "No disponible" }}
        </template>
        <template v-slot:[`item.activo`]="{ item }">
          <v-chip class="ma-0" :color="item.activo ? 'success' : 'error'" text-color="white" small>
            {{ item.activo ? "Activo" : "Inactivo" }}
          </v-chip>
        </template>
        <template v-slot:[`footer.page-text`]="props"> Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }} </template>
        <template v-slot:no-data> No hay información disponible </template>
        <template v-slot:no-results> No se obtuvieron resultados </template>
      </v-data-table>
    </Table-Container>
    <Dialog-Form :estate="dialogs.new" title="Nuevo usuario">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="save()" :loading="loading" />
      </template>
      <v-form ref="formNew" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.email" label="Correo" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.password" label="Contraseña" prepend-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :rules="rules.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.new.nombre" label="Nombre" prepend-icon="mdi-account" :rules="rules.nombre" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="6" md="12">
            <v-text-field v-model="forms.new.apellido_paterno" label="Apellido Paterno" prepend-icon="mdi-alpha-p" :rules="rules.apellido_paterno" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="6" md="12">
            <v-text-field v-model="forms.new.apellido_materno" label="Apellido Materno" prepend-icon="mdi-alpha-m" :rules="rules.apellido_materno" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.new.telefono" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.telefono" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <Date-Picker v-model="forms.new.fecha_ingreso_grupo" label="Fecha de Ingreso a la empresa" />
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.update" title="Actualizar usuario">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="update()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.email" label="Correo" prepend-icon="mdi-email" disabled outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.password" label="Contraseña" prepend-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" disabled outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-account" :rules="rules.nombre" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.update.apellido_paterno" label="Apellido Paterno" prepend-icon="mdi-alpha-p" :rules="rules.apellido_paterno" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.update.apellido_materno" label="Apellido Materno" prepend-icon="mdi-alpha-m" :rules="rules.apellido_materno" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.update.telefono" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.telefono" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <Date-Picker v-model="forms.update.fecha_ingreso_grupo" label="Fecha de Ingreso a la empresa" />
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.roles" title="Actualizar roles">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="updateRol()" :loading="loading" />
      </template>
      <v-form ref="formUpdate" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.update.nombre" label="Nombre" prepend-icon="mdi-account" disabled outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-autocomplete v-model="forms.update.roles" :items="catalogues.roles" item-text="rol" item-value="idrol" label="Rol" prepend-icon="mdi-shield-account" multiple chips small-chips hide-details persistent-hint no-data-text="No se encontraron resultados" outlined dense></v-autocomplete>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.reset" title="Actualizar contraseña">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" icon="mdi-check" @click="resetPassword()" :loading="loading" />
      </template>
      <v-form ref="formReset" lazy-validation>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.reset.email" label="Correo" prepend-icon="mdi-email" disabled outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.reset.password" label="Contraseña" prepend-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :rules="rules.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :disabled="loading" outlined dense required></v-text-field>
            <v-text-field v-model="forms.reset.confirmPassword" label="Confirmar contraseña" prepend-icon="mdi-form-textbox-password" :type="show ? 'text' : 'password'" :rules="rules.password" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show = !show" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Dialog-Form :estate="dialogs.address" title="Actualizar direccion">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="error" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Actualizar" color="success" icon="mdi-check" @click="saveAddress()" :loading="loading" />
      </template>
      <v-form ref="formAddress" lazy-validation>
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.pais" label="País" prepend-icon="mdi-city-variant-outline" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.estado" label="Estado" prepend-icon="mdi-city" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="8">
            <v-text-field v-model="forms.address.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field type="number" v-model.number="forms.address.cp" label="CP" prepend-icon="mdi-pound" :rules="rules.addressValid" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.colonia" label="Colonia" prepend-icon="mdi-home-group" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.address50" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.address.calle" label="Calle" prepend-icon="mdi-home-outline" :rules="rules.address100" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.num_ext" label="Numero exterior" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.address20" :disabled="loading" outlined dense required></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field v-model="forms.address.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.address20" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.address.entre_calles" label="Entre calles" prepend-icon="mdi-car-traction-control" :rules="rules.address100" :disabled="loading" outlined dense></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field v-model="forms.address.referencias" label="Referencias" prepend-icon="mdi-home-search-outline" :rules="rules.address100" :disabled="loading" outlined dense></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </Dialog-Form>
    <Delete-Message :estate="dialogs.remove" msg="¿Realmente desea eliminar el usuario?">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="cancel()" :loading="loading" />
        <Basic-Btn text="Eliminar" color="error" icon="mdi-check" @click="remove()" :loading="loading" />
      </template>
    </Delete-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" />
  </v-container>
</template>

<script>
import services from "@/utils/services";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, DatePicker } from "@/components";
import downloadjs from "downloadjs";

export default {
  name: "user",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    DatePicker,
  },
  data: () => ({
    loading: false,
    alert: {
      active: false,
      color: "error",
      msg: "",
    },
    search: "",
    headers: [
      { text: "ID", align: "left", sortable: true, value: "idusuario" },
      { text: "Nombre", align: "left", sortable: true, value: "detalles_usuarios[0].nombre" },
      { text: "Apellido Paterno", align: "left", sortable: true, value: "detalles_usuarios[0].apellido_paterno" },
      { text: "Apellido Materno", align: "left", sortable: true, value: "detalles_usuarios[0].apellido_materno" },
      { text: "Telefono", align: "left", sortable: true, value: "detalles_usuarios[0].telefono" },
      { text: "Rol", align: "left", sortable: true, value: "usuarios_roles[0].rol.rol" },
      { text: "Direccion", align: "left", sortable: true, value: "usuarios_direcciones" },
      { text: "Activo", align: "left", sortable: true, value: "activo" },
      { text: "Acciones", align: "left", sortable: false, value: "actions" },
    ],
    items: [],
    selected: [],
    deleted: null,
    show: false,
    catalogues: {
      sectores_profesionales: [],
      empresas: [],
      roles: [],
    },
    forms: {
      new: {
        email: "",
        password: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        telefono: "",
        empresa: "",
        direccion: "",
        municipio: "",
        estado: "",
        fecha_ingreso_grupo: "",
      },
      update: {
        idusuario: "",
        email: "",
        password: "",
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        telefono: "",
        empresa: "",
        direccion: "",
        municipio: "",
        estado: "",
        jefe_directo: "",
        fecha_alta: "",
        fecha_baja: "",
        estado_usuario: "",
        fecha_ingreso_grupo: "",
      },
      reset: {
        idusuario: "",
        password: "",
        confirmPassword: "",
      },
      address: {
        calle: "",
        calle: "",
        num_ext: "",
        num_int: "",
        entre_calles: "",
        referencias: "",
        colonia: "",
        sector: "",
        cp: 0,
        municipio: "",
        estado: "",
        sector_profesional: "",
        pais: "",
        referencia: "",
      },
    },
    idUpdate: 0,

    rules: {
      email: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 320) || "El campo excede la longitud máxima", (v) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || "El campo es inválido"],
      password: [
        (v) => !!v || "El campo es requerido",
        (v) => (v && v.length >= 8) || "La contraseña debe contener mínimo 8 caracteres alfanuméricos, incluyendo al menos una letra y un número",
        (v) => (v && v.length <= 60) || "La contraseña excede la longitud máxima",
        (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d@$!%*#?&]{8,}$/.test(v) || "La contraseña debe contener mínimo 8 caracteres, incluyendo al menos una letra y un número",
      ],
      nombre: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
      apellido_paterno: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
      apellido_materno: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
      telefono: [(v) => !!v || "El campo es requerido", (v) => (v && v.length <= 15) || "El campo excede la longitud máxima"],
      addressValid: [(v) => !!v || "El campo es requerido"],
      address20: [(v) => (v && v.length <= 20) || "El campo excede la longitud máxima"],
      address50: [(v) => (v && v.length <= 50) || "El campo excede la longitud máxima"],
      address100: [(v) => (v && v.length <= 100) || "El campo excede la longitud máxima"],
    },
    dialogs: {
      new: false,
      update: false,
      companies: false,
      roles: false,
      reset: false,
      address: false,

      remove: false,
    },
  }),
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      this.loading = true;
      const role = await this.axios.get(services.routes.role);
      if (role.data.data) {
        this.catalogues.roles = role.data.data;
      }
      this.axios
        .get(services.routes.user)
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset();
        for (let key in this.forms.new) {
          this.forms.new[key] = "";
        }
        this.dialogs.new = false;
      } else if (this.dialogs.update || this.dialogs.companies || this.dialogs.roles) {
        this.$refs.formUpdate.reset();
        for (let key in this.forms.update) {
          this.forms.update[key] = "";
        }
        this.dialogs.update = false;
        this.dialogs.companies = false;
        this.dialogs.roles = false;
      } else if (this.dialogs.reset) {
        this.$refs.formReset.reset();
        for (let key in this.forms.reset) {
          this.forms.reset[key] = "";
        }
        this.dialogs.reset = false;
      } else if (this.dialogs.address) {
        for (let key in this.forms.address) {
          if (key == "cp") {
            this.forms.address[key] = 0;
          } else {
            this.forms.address[key] = "";
          }
        }
        this.dialogs.address = false;
      } else if (this.dialogs.remove) {
        this.dialogs.remove = false;
      }
      this.show = false;
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.new));
        this.axios
          .post(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario creado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    view(data, type) {
      this.forms.update = {};
      if (type === "roles") {
        this.forms.update.usuario = data.idusuario;
        this.forms.update.nombre = data.detalles_usuarios[0].nombre + " " + data.detalles_usuarios[0].apellido_paterno + " " + data.detalles_usuarios[0].apellido_materno;
        this.forms.update.rol = null;
        if (data.usuarios_roles.length) {
          this.forms.update.roles = [];
          for (let i of data.usuarios_roles) {
            this.forms.update.roles.push(i.rol.idrol);
          }
        }
        this.dialogs.roles = true;
      } else {
        this.forms.update = {
          idusuario: data.idusuario,
          email: data.email,
          nombre: data.detalles_usuarios[0].nombre,
          apellido_paterno: data.detalles_usuarios[0].apellido_paterno,
          apellido_materno: data.detalles_usuarios[0].apellido_materno,
          password: "********************",
          telefono: data.detalles_usuarios[0].telefono,
          estado: data.detalles_usuarios[0].estado,
          municipio: data.detalles_usuarios[0].municipio,
          direccion: data.detalles_usuarios[0].direccion,
          fecha_ingreso_grupo: data.detalles_usuarios[0].fecha_ingreso_grupo,
        };
        this.dialogs.update = true;
      }
    },
    viewAddress(data) {
      this.idUpdate = data.idusuario;
      if (data.usuarios_direcciones.length > 0) {
        for (let key in this.forms.address) {
          if (data.usuarios_direcciones[0].direccion[key]) {
            this.forms.address[key] = data.usuarios_direcciones[0].direccion[key];
          }
        }
      }
      this.dialogs.address = true;
    },
    saveAddress() {
      this.loading = true;
      let aux = this.forms.address;
      let data = {
        usuario: this.idUpdate,
        direccion: aux,
      };
      this.axios
        .put(services.routes.user + "/address", { data })
        .then(() => {
          this.cancel();
          this.refresh();
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(this.forms.update));
        this.axios
          .put(services.routes.user, { data })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario actualizado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    updateRol() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true;
        this.forms.update.roles = [...this.forms.update.roles];
        let data = JSON.parse(JSON.stringify(this.forms.update));
        if (this.forms.update.roles.length > 0) {
          this.axios
            .put(services.routes.roleUser, { data })
            .then((response) => {
              this.cancel();
              this.refresh();
              this.alert.active = true;
              this.alert.color = "success";
              this.alert.msg = "Usuario actualizado exitosamente";
            })
            .catch((error) => {
              this.alert.active = true;
              this.alert.color = "error";
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "Se debe ingresar roles de usuario.";
          this.loading = false;
        }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    viewReset(data) {
      this.forms.reset = {
        idusuario: data.idusuario,
        email: data.email,
        password: "",
      };
      this.dialogs.reset = true;
    },
    resetPassword() {
      if (this.$refs.formReset.validate()) {
        if (this.forms.reset.password === this.forms.reset.confirmPassword) {
          this.loading = true;
          let data = JSON.parse(JSON.stringify(this.forms.reset));
          delete data.confirmPassword;
          this.axios
            .put(services.routes.user + "/password", { data })
            .then((response) => {
              this.cancel();
              this.refresh();
              this.alert.active = true;
              this.alert.color = "success";
              this.alert.msg = "Contraseña actualizada exitosamente";
            })
            .catch((error) => {
              this.alert.active = true;
              this.alert.color = "error";
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            })
            .finally(() => {
              this.loading = false;
            });
        } else {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = "Las contraseñas no coinciden";
        }
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "La información es incorrecta";
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true;
        let id = this.deleted;
        this.axios
          .delete(services.routes.user, { data: { data: { idusuario: this.deleted } } })
          .then((response) => {
            this.cancel();
            this.refresh();
            this.alert.active = true;
            this.alert.color = "success";
            this.alert.msg = "Usuario eliminado exitosamente";
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.deleted = id;
        this.dialogs.remove = true;
      }
    },
    activeUser(id) {
      this.axios
        .put(services.routes.user + "/updateStatus", { data: { usuario: id } })
        .then((response) => {
          this.cancel();
          this.refresh();
          this.alert.active = true;
          this.alert.color = "success";
          this.alert.msg = "Usuario eliminado exitosamente";
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validColor(n) {
      let color = "";
      let name = n.toLowerCase();
      if (name === "administrador") {
        color = "error";
      } else if (name === "coordinador") {
        color = "primary";
      } else if (name === "supervisor") {
        color = "secondary";
      } else if (name === "encargado") {
        color = "success";
      } else if (name === "publico") {
        color = "teal darken-3";
      }
      return color;
    },
    downloadReport() {
      this.loading = true;
      let url = services.routes.user;
      this.axios({
        method: "get",
        url: url + "/report",
        responseType: "blob",
      })
        .then((response) => {
          let fileName = response.headers["content-disposition"].split("attachment; filename=");
          downloadjs(response.data, fileName[1]);
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
          this.animation = "loader";
        });
    },
  },
};
</script>

<style scoped></style>
